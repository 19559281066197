/** @format */

import axios from "axios";
import store from "../";

const state = {
    noPaginationShipmentLists: {
		allShipments: {
			current_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		awaitingDeparture: {
			current_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		inTransit: {
			current_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		arrived: {
			current_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		pickedUp: {
			current_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		requiresAttention: {
			current_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
	},
    shipmentsPaginationLists: {
		allShipments: {
			current_page: 1,
            old_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		awaitingDeparture: {
			current_page: 1,
            old_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		inTransit: {
			current_page: 1,
            old_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		arrived: {
			current_page: 1,
            old_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		pickedUp: {
			current_page: 1,
            old_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
		requiresAttention: {
			current_page: 1,
            old_page: 1,
			last_page: 1,
			shipments: [],
			tab: "",
		},
	},
    shipmentsListsLoading: {
        allShipmentsLoading: false,
        awaitingDepartureLoading: false,
        inTransitLoading: false,
        arrivedLoading: false,
        pickedUpLoading: false,
        requiresAttentionLoading: false
    },
	currentShipmentSubtab: 0,
	currentViewTab: 0,
	hasPastLastFreeDay: false
};

// const betaBaseUrl = process.env.VUE_APP_BASE_URL;
// const poBaseUrl = process.env.VUE_APP_PO_URL;

const getters = {
    getAllShipmentsTab: (state) => state.shipmentsPaginationLists.allShipments,
    getAllShipmentsTabLoading: (state) => state.shipmentsListsLoading.allShipmentsLoading,
    getAwaitingDepartureShipments: (state) => state.shipmentsPaginationLists.awaitingDeparture,
    getAwaitingDepartureShipmentsLoading: (state) => state.shipmentsListsLoading.awaitingDepartureLoading,
    getInTransitShipments: (state) => state.shipmentsPaginationLists.inTransit,
    getInTransitShipmentsLoading: (state) => state.shipmentsListsLoading.inTransitLoading,
    getArrivedShipments: (state) => state.shipmentsPaginationLists.arrived,
    getArrivedShipmentsLoading: (state) => state.shipmentsListsLoading.arrivedLoading,
    getPickedUpShipments: (state) => state.shipmentsPaginationLists.pickedUp,
    getPickedUpShipmentsLoading: (state) => state.shipmentsListsLoading.pickedUpLoading,
    getRequiresAShipments: (state) => state.shipmentsPaginationLists.requiresAttention,
    getRequiresAShipmentsLoading: (state) => state.shipmentsListsLoading.requiresAttentionLoading,
	getCurrentShipmentSubtab: (state) => state.currentShipmentSubtab,
	getCurrentViewTab: (state) => state.currentViewTab,
	getHasPastLastFreeDay: (state) => state.hasPastLastFreeDay,
};

const actions = {
    fetchAllShipments: async ({ commit, state }, payload) => {
		let attempt = false;
		return new Promise((resolve, reject) => {
			function proceed() {
				commit("SET_ALL_SHIPMENTS_LOADING", true);
				let { page = 1, order = "asc", sort = "eta" } = payload;

				let passedData = {
					method: "get",
					url: "/v2/shipment-tab",
					params: {
						shipment_tab: "shipments",
						page,
						per_page: 20,
						order_by: order,
						// sort_column: state.shipmentOrder.field || sort,
                        sort_column: sort,
						compact_view: false,
					},
				};
				axios(passedData)
					.then((res) => {
						if (res.status === 200) {
							if (typeof res.data !== "undefined") {
								let shipmentsData = res.data;

								let finalData = {
									current_page: shipmentsData.current_page,
									per_page: shipmentsData.per_page,
									total: shipmentsData.total,
									last_page: shipmentsData.last_page,
									shipments: shipmentsData.data,
                                    old_page: shipmentsData.current_page
								};
								commit("SET_ALL_SHIPMENTS", finalData);

								// if current page is 1, push initial data to shipments no pagination getters
								if (shipmentsData.current_page === 1 &&
									state.noPaginationShipmentLists.allShipments.shipments.length !== shipmentsData.total) {
									let data = {
										current_page: shipmentsData.current_page,
										last_page: shipmentsData.last_page,
										shipments: shipmentsData.data,
										tab: "shipments",
									};
									commit("SET_ALL_SHIPMENTS_NO_PAGINATION_SHIPMENTS", data);
								}
							}
						}
						commit("SET_ALL_SHIPMENTS_LOADING", false);
						resolve();
					})
					.catch((err) => {
						if (typeof err.message !== "undefined") {
							if (!attempt) {
								attempt = true;
								let t = setInterval(() => {
									if (!store.getters.getIsRefreshing) {
										proceed();
										clearInterval(t);
									}
								}, 300);
							} else {
								console.log("reject", err);
								commit("SET_ALL_SHIPMENTS_LOADING", false);
								reject("Token has been expired. Please reload the page.");
							}
						}

						if (typeof err.error !== "undefined") {
							commit("SET_ALL_SHIPMENTS_LOADING", false);
							reject(err.error);
						}
					});
			}
			proceed();
		});
	},
	fetchAwaitingShipments: async ({ commit, state }, payload) => {
		let attempt = false;
		return new Promise((resolve, reject) => {
			function proceed() {
				commit("SET_AWAITING_SHIPMENTS_LOADING", true);
				let { page = 1, order = "asc", sort = "eta" } = payload;

				let passedData = {
					method: "get",
					url: "/v2/shipment-tab",
					params: {
						shipment_tab: "shipments",
						page,
						per_page: 20,
						order_by: order,
						// sort_column: state.shipmentOrder.field || sort,
                        sort_column: sort,
						compact_view: false,
						shipments_sub_tab: "awaiting_departure"
					},
				};
				axios(passedData)
					.then((res) => {
						if (res.status === 200) {
							if (typeof res.data !== "undefined") {
								let shipmentsData = res.data;

								let finalData = {
									current_page: shipmentsData.current_page,
									per_page: shipmentsData.per_page,
									total: shipmentsData.total,
									last_page: shipmentsData.last_page,
									shipments: shipmentsData.data,
                                    old_page: shipmentsData.current_page
								};
								commit("SET_AWAITING_SHIPMENTS", finalData);
								store.state.shipment.shipmentSubtabsCount.awaitingDepartureCount = shipmentsData.total;

								// if current page is 1, push initial data to shipments no pagination getters
								if (shipmentsData.current_page === 1 &&
									state.noPaginationShipmentLists.awaitingDeparture.shipments.length !== shipmentsData.total) {
									let data = {
										current_page: shipmentsData.current_page,
										last_page: shipmentsData.last_page,
										shipments: shipmentsData.data,
										tab: "shipments2",
									};
									commit("SET_AWAITING_SHIPMENTS_NO_PAGINATION_SHIPMENTS", data);
								}
							}
						}
						commit("SET_AWAITING_SHIPMENTS_LOADING", false);
						resolve();
					})
					.catch((err) => {
						if (typeof err.message !== "undefined") {
							if (!attempt) {
								attempt = true;
								let t = setInterval(() => {
									if (!store.getters.getIsRefreshing) {
										proceed();
										clearInterval(t);
									}
								}, 300);
							} else {
								console.log("reject", err);
								commit("SET_AWAITING_SHIPMENTS_LOADING", false);
								reject("Token has been expired. Please reload the page.");
							}
						}

						if (typeof err.error !== "undefined") {
							commit("SET_AWAITING_SHIPMENTS_LOADING", false);
							reject(err.error);
						}
					});
			}
			proceed();
		});
	},
    fetchInTransitShipments: async ({ commit, state }, payload) => {
		let attempt = false;
		return new Promise((resolve, reject) => {
			function proceed() {
				commit("SET_INTRANSIT_SHIPMENTS_LOADING", true);
				let { page = 1, order = "asc", sort = "eta" } = payload;

				let passedData = {
					method: "get",
					url: "/v2/shipment-tab",
					params: {
						shipment_tab: "shipments",
						page,
						per_page: 20,
						order_by: order,
						// sort_column: state.shipmentOrder.field || sort,
                        sort_column: sort,
						compact_view: false,
						shipments_sub_tab: "in_transit"
					},
				};
				axios(passedData)
					.then((res) => {
						if (res.status === 200) {
							if (typeof res.data !== "undefined") {
								let shipmentsData = res.data;

								let finalData = {
									current_page: shipmentsData.current_page,
									per_page: shipmentsData.per_page,
									total: shipmentsData.total,
									last_page: shipmentsData.last_page,
									shipments: shipmentsData.data,
                                    old_page: shipmentsData.current_page
								};
								commit("SET_INTRANSIT_SHIPMENTS", finalData);
								store.state.shipment.shipmentSubtabsCount.inTransitCount = shipmentsData.total;

								// if current page is 1, push initial data to shipments no pagination getters
								if (shipmentsData.current_page === 1 &&
									state.noPaginationShipmentLists.inTransit.shipments.length !== shipmentsData.total) {
									let data = {
										current_page: shipmentsData.current_page,
										last_page: shipmentsData.last_page,
										shipments: shipmentsData.data,
										tab: "shipments3",
									};
									commit("SET_INTRANSIT_SHIPMENTS_NO_PAGINATION_SHIPMENTS", data);
								}
							}
						}
						commit("SET_INTRANSIT_SHIPMENTS_LOADING", false);
						resolve();
					})
					.catch((err) => {
						if (typeof err.message !== "undefined") {
							if (!attempt) {
								attempt = true;
								let t = setInterval(() => {
									if (!store.getters.getIsRefreshing) {
										proceed();
										clearInterval(t);
									}
								}, 300);
							} else {
								console.log("reject", err);
								commit("SET_INTRANSIT_SHIPMENTS_LOADING", false);
								reject("Token has been expired. Please reload the page.");
							}
						}

						if (typeof err.error !== "undefined") {
							commit("SET_INTRANSIT_SHIPMENTS_LOADING", false);
							reject(err.error);
						}
					});
			}
			proceed();
		});
	},
    fetchArrivedShipments: async ({ commit, state }, payload) => {
		let attempt = false;
		return new Promise((resolve, reject) => {
			function proceed() {
				commit("SET_ARRIVED_SHIPMENTS_LOADING", true);
				let { page = 1, order = "asc", sort = "eta" } = payload;

				let passedData = {
					method: "get",
					url: "/v2/shipment-tab",
					params: {
						shipment_tab: "shipments",
						page,
						per_page: 20,
						order_by: order,
						// sort_column: state.shipmentOrder.field || sort,
                        sort_column: sort,
						compact_view: false,
						shipments_sub_tab: "arrived"
					},
				};
				axios(passedData)
					.then((res) => {
						if (res.status === 200) {
							if (typeof res.data !== "undefined") {
								let shipmentsData = res.data;

								let finalData = {
									current_page: shipmentsData.current_page,
									per_page: shipmentsData.per_page,
									total: shipmentsData.total,
									last_page: shipmentsData.last_page,
									shipments: shipmentsData.data,
                                    old_page: shipmentsData.current_page
								};
								commit("SET_ARRIVED_SHIPMENTS", finalData);
								store.state.shipment.shipmentSubtabsCount.arrivedCount = shipmentsData.total;

								// if current page is 1, push initial data to shipments no pagination getters
								if (shipmentsData.current_page === 1 &&
									state.noPaginationShipmentLists.arrived.shipments.length !== shipmentsData.total) {
									let data = {
										current_page: shipmentsData.current_page,
										last_page: shipmentsData.last_page,
										shipments: shipmentsData.data,
										tab: "shipments4",
									};
									commit("SET_ARRIVED_SHIPMENTS_NO_PAGINATION_SHIPMENTS", data);
								}
							}
						}
						commit("SET_ARRIVED_SHIPMENTS_LOADING", false);
						resolve();
					})
					.catch((err) => {
						if (typeof err.message !== "undefined") {
							if (!attempt) {
								attempt = true;
								let t = setInterval(() => {
									if (!store.getters.getIsRefreshing) {
										proceed();
										clearInterval(t);
									}
								}, 300);
							} else {
								console.log("reject", err);
								commit("SET_ARRIVED_SHIPMENTS_LOADING", false);
								reject("Token has been expired. Please reload the page.");
							}
						}

						if (typeof err.error !== "undefined") {
							commit("SET_ARRIVED_SHIPMENTS_LOADING", false);
							reject(err.error);
						}
					});
			}
			proceed();
		});
	},
    fetchPickedUpShipments: async ({ commit, state }, payload) => {
		let attempt = false;
		return new Promise((resolve, reject) => {
			function proceed() {
				commit("SET_PICKEDUP_SHIPMENTS_LOADING", true);
				let { page = 1, order = "asc", sort = "eta" } = payload;

				let passedData = {
					method: "get",
					url: "/v2/shipment-tab",
					params: {
						shipment_tab: "shipments",
						page,
						per_page: 20,
						order_by: order,
						// sort_column: state.shipmentOrder.field || sort,
                        sort_column: sort,
						compact_view: false,
						shipments_sub_tab: "pickedup"
					},
				};
				axios(passedData)
					.then((res) => {
						if (res.status === 200) {
							if (typeof res.data !== "undefined") {
								let shipmentsData = res.data;

								let finalData = {
									current_page: shipmentsData.current_page,
									per_page: shipmentsData.per_page,
									total: shipmentsData.total,
									last_page: shipmentsData.last_page,
									shipments: shipmentsData.data,
                                    old_page: shipmentsData.current_page
								};
								commit("SET_PICKEDUP_SHIPMENTS", finalData);
								store.state.shipment.shipmentSubtabsCount.pickedUpCount = shipmentsData.total;

								// if current page is 1, push initial data to shipments no pagination getters
								if (shipmentsData.current_page === 1 &&
									state.noPaginationShipmentLists.pickedUp.shipments.length !== shipmentsData.total) {
									let data = {
										current_page: shipmentsData.current_page,
										last_page: shipmentsData.last_page,
										shipments: shipmentsData.data,
										tab: "shipments5",
									};
									commit("SET_PICKEDUP_SHIPMENTS_NO_PAGINATION_SHIPMENTS", data);
								}
							}
						}
						commit("SET_PICKEDUP_SHIPMENTS_LOADING", false);
						resolve();
					})
					.catch((err) => {
						if (typeof err.message !== "undefined") {
							if (!attempt) {
								attempt = true;
								let t = setInterval(() => {
									if (!store.getters.getIsRefreshing) {
										proceed();
										clearInterval(t);
									}
								}, 300);
							} else {
								console.log("reject", err);
								commit("SET_PICKEDUP_SHIPMENTS_LOADING", false);
								reject("Token has been expired. Please reload the page.");
							}
						}

						if (typeof err.error !== "undefined") {
							commit("SET_PICKEDUP_SHIPMENTS_LOADING", false);
							reject(err.error);
						}
					});
			}
			proceed();
		});
	},
    fetchRequiresAttentionShipments: async ({ commit, state }, payload) => {
		let attempt = false;
		return new Promise((resolve, reject) => {
			function proceed() {
				commit("SET_REQUIRES_SHIPMENTS_LOADING", true);
				let { page = 1, order = "asc", sort = "eta" } = payload;

				let passedData = {
					method: "get",
					url: "/v2/shipment-tab",
					params: {
						shipment_tab: "shipments",
						page,
						per_page: 20,
						order_by: order,
						// sort_column: state.shipmentOrder.field || sort,
                        sort_column: sort,
						compact_view: false,
						shipments_sub_tab: "required_attention"
                        // filters: JSON.stringify(filtersPassed)
					},
				};
				axios(passedData)
					.then((res) => {
						if (res.status === 200) {
							if (typeof res.data !== "undefined") {
								let shipmentsData = res.data;

								let finalData = {
									current_page: shipmentsData.current_page,
									per_page: shipmentsData.per_page,
									total: shipmentsData.total,
									last_page: shipmentsData.last_page,
									shipments: shipmentsData.data,
                                    old_page: shipmentsData.current_page,
									past_last_free_day_status: shipmentsData.past_last_free_day_status
								};
								commit("SET_REQUIRES_SHIPMENTS", finalData);
								
								let plfd = shipmentsData.past_last_free_day_status;
								commit("SET_HAS_PAST_LAST_FREE_DAY", plfd);
								store.state.shipment.shipmentSubtabsCount.requiresCount = shipmentsData.total;

								// if current page is 1, push initial data to shipments no pagination getters
								if (shipmentsData.current_page === 1 &&
									state.noPaginationShipmentLists.requiresAttention.shipments.length !== shipmentsData.total) {
									let data = {
										current_page: shipmentsData.current_page,
										last_page: shipmentsData.last_page,
										shipments: shipmentsData.data,
										tab: "shipments6",
									};
									commit("SET_REQUIRES_SHIPMENTS_NO_PAGINATION_SHIPMENTS", data);
								}
							}
						}
						commit("SET_REQUIRES_SHIPMENTS_LOADING", false);
						resolve();
					})
					.catch((err) => {
						if (typeof err.message !== "undefined") {
							if (!attempt) {
								attempt = true;
								let t = setInterval(() => {
									if (!store.getters.getIsRefreshing) {
										proceed();
										clearInterval(t);
									}
								}, 300);
							} else {
								console.log("reject", err);
								commit("SET_REQUIRES_SHIPMENTS_LOADING", false);
								reject("Token has been expired. Please reload the page.");
							}
						}

						if (typeof err.error !== "undefined") {
							commit("SET_REQUIRES_SHIPMENTS_LOADING", false);
							reject(err.error);
						}
					});
			}
			proceed();
		});
	},
	setShipmentSubtab({ commit }, payload) {
        commit('setShipmentSubtab', payload);
    },
	setCurrentViewTab({ commit }, payload) {
        commit('setCurrentViewTab', payload);
    },
};
const mutations = {
	setShipmentSubtab: (state, payload) => {
        state.currentShipmentSubtab = payload;
    },
	setCurrentViewTab: (state, payload) => {
        state.currentViewTab = payload;
    },
    SET_ALL_SHIPMENTS_LOADING: (state, payload) => {
		state.shipmentsListsLoading.allShipmentsLoading = payload;
	},
	SET_ALL_SHIPMENTS: (state, payload) => {
		state.shipmentsPaginationLists.allShipments = payload;
	},
    SET_ALL_SHIPMENTS_NO_PAGINATION_SHIPMENTS: (state, payload) => {
		state.noPaginationShipmentLists.allShipments = payload;
	},

	SET_AWAITING_SHIPMENTS_LOADING: (state, payload) => {
		state.shipmentsListsLoading.awaitingDepartureLoading = payload;
	},
	SET_AWAITING_SHIPMENTS: (state, payload) => {
		state.shipmentsPaginationLists.awaitingDeparture = payload;
	},
    SET_AWAITING_SHIPMENTS_NO_PAGINATION_SHIPMENTS: (state, payload) => {
		state.noPaginationShipmentLists.awaitingDeparture = payload;
	},

    SET_INTRANSIT_SHIPMENTS_LOADING: (state, payload) => {
		state.shipmentsListsLoading.inTransitLoading = payload;
	},
	SET_INTRANSIT_SHIPMENTS: (state, payload) => {
		state.shipmentsPaginationLists.inTransit = payload;
	},
    SET_INTRANSIT_SHIPMENTS_NO_PAGINATION_SHIPMENTS: (state, payload) => {
		state.noPaginationShipmentLists.inTransit = payload;
	},

    SET_ARRIVED_SHIPMENTS_LOADING: (state, payload) => {
		state.shipmentsListsLoading.arrivedLoading = payload;
	},
	SET_ARRIVED_SHIPMENTS: (state, payload) => {
		state.shipmentsPaginationLists.arrived = payload;
	},
    SET_ARRIVED_SHIPMENTS_NO_PAGINATION_SHIPMENTS: (state, payload) => {
		state.noPaginationShipmentLists.arrived = payload;
	},

    SET_PICKEDUP_SHIPMENTS_LOADING: (state, payload) => {
		state.shipmentsListsLoading.pickedUpLoading = payload;
	},
	SET_PICKEDUP_SHIPMENTS: (state, payload) => {
		state.shipmentsPaginationLists.pickedUp = payload;
	},
    SET_PICKEDUP_SHIPMENTS_NO_PAGINATION_SHIPMENTS: (state, payload) => {
		state.noPaginationShipmentLists.pickedUp = payload;
	},

    SET_REQUIRES_SHIPMENTS_LOADING: (state, payload) => {
		state.shipmentsListsLoading.requiresAttentionLoading = payload;
	},
	SET_REQUIRES_SHIPMENTS: (state, payload) => {
		state.shipmentsPaginationLists.requiresAttention = payload;
	},
    SET_REQUIRES_SHIPMENTS_NO_PAGINATION_SHIPMENTS: (state, payload) => {
		state.noPaginationShipmentLists.requiresAttention = payload;
	},
	SET_HAS_PAST_LAST_FREE_DAY: (state, payload) => {
		state.hasPastLastFreeDay = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
