<!-- @format -->

<template>
	<v-list-item-content>
		<v-list-item-title class="mb-1">
			<!-- <span style="color: #253041;" class="font-medium">{{ title }}</span> -->
			<span v-if="!hasBeenRead" class="mr-1">
				<v-icon color="#0171A1" size="12px">mdi-circle</v-icon>
			</span>
			
			<span style="color: #253041;" class="font-medium" 
				:style="hasBeenRead ? 'margin-left: 20px;' : ''">
				{{ title }}
			</span>
		</v-list-item-title>

		<v-list-item-subtitle style="color: #69758C; margin-left: 19px;" class="font-regular">
			<div class="d-flex justify-space-between align-center">
				<span class="font-regular"> {{ subTitle }}</span>

				<!-- <v-tooltip left content-class="notification-tooltip-settings tooltip-left">
					<template v-slot:activator="{ on }">
						<span class="archive-notification d-flex align-center">
							<img src="@/assets/icons/archive.png" alt="archive" width="16px" height="16px" v-on="on">
						</span>				
					</template>

					<span>{{ !isShowArchived ? 'Archive' : 'Unarchive' }}</span>
				</v-tooltip> -->
			</div>
		</v-list-item-subtitle>

		<v-dialog v-model="dialog" width="500">
			<v-card>
				<v-card-title class="text-h5">
					File is ready!
				</v-card-title>
				<v-card-text class="pa-6"
					>For download file click
					<a :href="getLink" download>Click here</a>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red darken-1" text @click="dialog = false">
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-list-item-content>
</template>

<script>
import BaseNotification from "@/components/Notifications/Types/BaseNotification";
import { mapActions } from "vuex";
// import axios from "axios";

export default {
	extends: BaseNotification,
	name: "ExportOrderListSuccessNotification",
	data() {
		return {
			item: {},
			isOpen: false,
			templateURL: `${process.env.VUE_APP_PO_URL}/products/export-template`,
			dialog: false,
		};
	},
	created() {},
	computed: {
		getLink() {
			return this.notification.data?.returnURL;
		},
	},
	methods: {
		...mapActions({
			importProducts: "products/importProducts",
		}),
		onOpen() {
			this.dialog = true;
		},
	},
};
</script>
<style scoped lang="scss"></style>
