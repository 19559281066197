<!-- @format -->

<template>
	<div class="v-list-item-wrapper" :class="hoverBtn.status ? 'show' : ''" v-resize="onResize">
		<v-list-item
			class="notification-list-item d-block"
			@click.stop="clickedOnNotification"
			:class="{
				'notification-unread': !notification.read_at,
				'drag-message' : dragMessage.id == notification.id && dragMessage.status
			}"
			:style="dragMessage.id == notification.id && dragMessage.status ? `transform: ${transformStyle}` : ''"
			@touchstart="startDrag"
			@touchmove="moveDrag($event, notification.id)"
			@touchend="endDrag()">

			<component
				ref="component"
				:is="componentType"
				:notification="notification"
				:isShowArchived="isShowArchived"
				:isMobile="isMobile"
				:hasNewNotifications="hasNewNotifications"
				:sortBy="sortBy"
			/>
			<!-- <div
				class="text-right text-caption font-regular"
				style="
					color: #69758C !important; 
					font-family: 'Inter-Regular', sans-serif !important; 
					letter-spacing: 0 !important;"
			>
				{{ notification.created_at | humanize }}
			</div> -->
		</v-list-item>

		<div v-if="hoverBtn.status" class="archived-mobile-img">
			<img src="@/assets/icons/archive.png" alt="archive" width="16px" height="16px">
		</div>
		<v-divider style="border-color: #F3F4F7;"></v-divider>
	</div>
</template>

<script>
import BaseNotification from "@/components/Notifications/Types/BaseNotification";
import ImportProductsFailedNotification from "@/components/Notifications/Types/ImportProductsFailedNotification";
import ImportProductsSuccessNotification from "@/components/Notifications/Types/ImportProductsSuccessNotification";
import ImportPurchaseOrdersFailedNotification from "@/components/Notifications/Types/ImportPurchaseOrdersFailedNotification";
import ImportPurchaseOrdersSuccessNotification from "@/components/Notifications/Types/ImportPurchaseOrdersSuccessNotification";
import ExportOrderListSuccessNotification from "@/components/Notifications/Types/ExportOrderListSuccessNotification";
import { mapActions, mapMutations } from "vuex";
export default {
	components: {
		BaseNotification,
		ImportProductsFailedNotification,
		ImportProductsSuccessNotification,
		ImportPurchaseOrdersFailedNotification,
		ImportPurchaseOrdersSuccessNotification,
		ExportOrderListSuccessNotification,
	},
	name: "Notification",
	props: {
		notification: {
			required: true,
		},
		isShowArchived: Boolean,
		hasNewNotifications: Boolean,
		sortBy: String
	},
	data: () => ({
		hoverInfo: {
			id: null,
			status: false,
		},
		hoverBtn: {
			id: null,
			status: false,
		},
		hoverRead: {
			id: null,
			status: false,
		},
		dragMessage: {
			id: null,
			status: false,
		},
		startX: 0,
		transformStyle: "",
		isMobile: false
	}),
	computed: {
		componentType() {
			if (typeof this.notification.type !== "undefined") {
				const componentName = this.notification.type.split("\\").pop();
				return this.componentIsAvailable(componentName)
					? componentName
					: "BaseNotification";
			} else {
				return this.componentIsAvailable("ShipmentNotification");
			}
			// const componentName = this.notification.type.split("\\").pop();
			// return this.componentIsAvailable(componentName)
			// 	? componentName
			// 	: "BaseNotification";
		},
	},
	methods: {
		...mapActions({			
			archiveOrUnarchiveNotification: "notificationNew/archiveOrUnarchiveNotification",
		}),
		...mapMutations({
			openDocumentPreview:'notificationNew/openDocumentPreview'
		}),
		componentIsAvailable(componentName) {
			return !!this.$options.components[componentName];
		},
		clickedOnNotification() {
			if (typeof this.$refs.component.action === "function")
				this.$refs.component.action();

			if (this.notification.data.type === "document_upload") {
				let data  = {...this.notification.data, from: "document_notification" };
				this.openDocumentPreview(data)
				this.$router.push(`/shipment/${this.notification.data.data.shipment_id}`).catch(() => {});
				this.$emit("close");
			} else {
				if (typeof this.notification.data.shipment_id !== "undefined") {
					this.$router.push(`/shipment/${this.notification.data.shipment_id}`).catch(() => {});
					this.$emit("close");
				}				
			}
		},
		onResize() {
			if (window.innerWidth < 769) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
		},
		startDrag(e) {
			this.startX = e.touches[0].clientX;
		},
		moveDrag(e, id) {
			const componentName = this.notification.type.split("\\").pop();			
			if (componentName === "ShipmentNotification") {
				const currentX = e.touches[0].clientX;
				const deltaX = currentX - this.startX;
				this.dragMessage.status = deltaX < 0 ? true : false;			
				this.dragMessage.id = deltaX < 0 ? id : null;
				this.transformStyle = `translateX(${deltaX}px)`;

				if (deltaX <= -50) {
					this.hoverBtn.id = id;
					this.hoverBtn.status = true;
				} else {
					this.hoverBtn.id = null;
					this.hoverBtn.status = false;
				}
			}
		},
		endDrag() {
			if (this.hoverBtn.status && this.dragMessage.id) {
				this.archiveNotif();
			}
		},
		async archiveNotif() {
			let convertedString = undefined
			if(this.sortBy !== undefined && this.sortBy !== 'undefined'){
				convertedString = this.sortBy.toLowerCase().replace(/\s/g, '_');
			}else{
				convertedString = undefined
			}
			let data = { id: this.notification.id, isFor: "", sortBy:convertedString };
			data.isFor = !this.isShowArchived ? "archive" : "unarchive";
			await this.archiveOrUnarchiveNotification(data);
		},
	},
};
</script>

<style scoped>
.bg-blue-overlay {
	background-color: #E1F4FF !important;
}
</style>

<style lang="scss">
.v-list-item-wrapper {
	position: relative;

	&.show {
		.archived-mobile-img {
			position: absolute;
			top: 38%;
			right: 15px;
			display: flex;
			align-items: center;
		}
	}

	.notification-list-item {
		transition: transform 0s ease;

		&.drag-message {
			transition: transform 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
		}
	}
}
</style>
