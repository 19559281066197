import axios from "axios"
// import router from '../../router/index'
// import Shipment from './../../custom/ShipmentResource'

const state = {
	masterSearch: [],
	masterSearchLoad: false,
	addOverlay:false
}

const getters = {
	getAllMasterSearch: state => state.masterSearch,
	masterSearchLoading: state => state.masterSearchLoad,
	getAddOverlay :state => state.addOverlay 
}

const actions = {
	setMasterSearchLoading({ commit }, payload) {
		commit("SET_MASTER_SEARCH_LOADING", payload)
	},
	fetchMasterSearch: async ({
		commit
	}, data ) => {

        // axios({
        //     method: "post",
        //     url: "/search",
        //     cancelToken: new CancelToken(function executor(c) {
        //         cancel = c
        //     }),
        //     params: {
        //         search_text: searchData
        //     }
        // })
        // .then(res => {                    
        //     if (res.status == 200) {
        //         this.searching = false
        //         this.searchResults = JSON.parse(JSON.stringify(res.data.data))
        //         console.log(res.data.data);
        //         this.isOpen = true
        //     }
        // })
        // .catch(err => {
        //     if (err.status == 'undefined') {
        //         this.isOpen = true
        //         this.searching = false
        //         this.searchResults = []
        //         console.log(JSON.stringify(err.response))
        //         throw Error(err)
        //     }
        // })
		commit("SET_MASTER_SEARCH_LOADING", true)
		await axios(data)
		.then(res => {         

			if (res.status == 200) {

				let finalData = {
					documents:   res.data.documents ,
					products: res.data.products ,
					purchase_order: res.data.purchase_order,
					shipments: res.data.shipments,
					suppliers: res.data.suppliers
				}

				commit("SET_MASTER_SEARCH", finalData)
				// commit("SET_MASTER_SEARCH_LOADING", false)
			}

			commit("SET_MASTER_SEARCH_LOADING", false)
		})
		.catch(err => {
			
			if (err.status == 'undefined') {
				commit("SET_MASTER_SEARCH_LOADING", false)
				console.log("myyy",JSON.stringify(err.response))
				throw Error(err)				
			}
			if(err.message == 'cancel_previous_request'){
				return
			}else{
			commit("SET_MASTER_SEARCH_LOADING", false)
			}
		})
	}
}

const mutations = {
	SET_MASTER_SEARCH: (state, payload) => {
		state.masterSearch = payload
	},
	SET_MASTER_SEARCH_LOADING: (state, payload) => {
		console.log("yes",payload)
		state.masterSearchLoad = payload
	},
	addOverlayMutate: (state, payload) => {
		state.addOverlay = payload
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}