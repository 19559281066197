var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar",class:_vm.isToggled ? 'toggled' : ''},[_c('div',{staticClass:"logo"},[_c('div',{staticClass:"shipment-logo"},[_c('router-link',{attrs:{"to":"/shipments"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-white.svg"),"alt":""}})]),_c('button',{staticClass:"toggle-sidebar-btn",attrs:{"title":_vm.isToggled ? 'Show Side Panel' : 'Hide Side Panel'},on:{"click":_vm.collapseSidePanel}},[(!_vm.isToggled)?_c('img',{attrs:{"src":require("@/assets/icons/show-side-panel.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icons/hide-side-panel.png"),"alt":""}})])],1)]),(_vm.customersApi.length === 0)?_c('ul',{staticClass:"links"},[(_vm.isMobile)?_c('li',[_c('v-btn',{staticClass:"btn-logout-mobile justify-start",staticStyle:{"width":"100%"},on:{"click":_vm.userLogout}},[_c('img',{staticStyle:{"margin-right":"10px"},attrs:{"src":require("@/assets/images/sign-out.png"),"alt":""}}),_vm._v(" Sign Out ")])],1):_vm._e()]):_vm._e(),(_vm.customersApi.length > 0)?_c('ul',{staticClass:"links",on:{"mouseenter":function($event){return _vm.showHoverState(true)},"mouseleave":function($event){return _vm.showHoverState(false)}}},[_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'shipments',
				},attrs:{"to":"/shipments"}},[_c('img',{attrs:{"src":require("@/assets/images/shipments.svg"),"alt":""}}),_vm._v(" Shipments ")])],1),_c('li',[_c('router-link',{class:{ 'active-link': _vm.$store.state.page.currentPage == 'pos',
				},attrs:{"to":"/pos?tab=active"}},[_c('img',{attrs:{"src":require("@/assets/images/POs.svg"),"alt":""}}),_vm._v(" POs ")])],1),_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'sales-orders',
				},attrs:{"to":"/sales-orders?tab=active"}},[_c('img',{attrs:{"src":require("@/assets/images/salesOrders.svg"),"alt":""}}),_vm._v(" Sales Orders ")])],1),_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'products',
				},attrs:{"to":"/products"}},[_c('img',{attrs:{"src":require("@/assets/images/Items.svg"),"alt":""}}),_vm._v(" Products ")])],1),_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'inventory',
				},attrs:{"to":"/inventory?tab=Products"},nativeOn:{"click":function($event){return _vm.setTabToProducts($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/Inventory.svg"),"alt":""}}),_vm._v(" Inventory ")])],1),_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'contact',
				},attrs:{"to":"/contact"}},[_c('img',{attrs:{"src":require("@/assets/images/Suppliers.svg"),"alt":""}}),_vm._v(" Contact ")])],1),_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'ach-statements',
				},attrs:{"to":"/ach-statements"}},[_c('img',{attrs:{"src":require("@/assets/images/statement-white.svg"),"alt":""}}),_vm._v(" ACH Statements ")])],1),_c('li',[_c('router-link',{class:{
					'active-link':
						_vm.$store.state.page.currentPage == 'reports',
				},attrs:{"to":"/reports"}},[_c('img',{attrs:{"src":require("@/assets/images/Reports.svg"),"alt":""}}),_vm._v(" Reports ")])],1),_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'billing',
				},attrs:{"to":"/billing"}},[_c('img',{attrs:{"src":require("@/assets/images/billing.svg"),"alt":""}}),_vm._v(" Billing ")])],1),_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'comapny-profile',
				},attrs:{"to":"/company-profile"}},[_c('img',{attrs:{"src":require("@/assets/images/Company-Profile.svg"),"alt":""}}),_vm._v(" Company Profile ")])],1),_c('div',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.settingsMenuShow),callback:function ($$v) {_vm.settingsMenuShow=$$v},expression:"settingsMenuShow"}},[_c('v-expansion-panel',{model:{value:(_vm.settingsMenuShow),callback:function ($$v) {_vm.settingsMenuShow=$$v},expression:"settingsMenuShow"}},[_c('v-expansion-panel-header',{class:_vm.$router.currentRoute.name === 'Settings' && _vm.isToggled ? 'toggled-settings' : ''},[_c('div',{staticClass:"settings-menu-group"},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg"),"alt":""}}),_vm._v(" Settings ")])]),_c('v-expansion-panel-content',[_c('v-list-item-group',{staticClass:"pa-0",class:_vm.$router.currentRoute.name === 'Settings' && _vm.isToggled ? 'toggled-settings' : '',attrs:{"two-line":""}},[_c('v-list-item',{class:_vm.$router.currentRoute.name === 'Settings' &&
									_vm.$store.state.page.currentSettingsTab == 0
										? 'active-link'
										: '',on:{"click":function($event){return _vm.onClickSettings(0)}}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":"/settings?tab=manage-payment-methods"}},[_c('div',{staticClass:"icon-separator"}),_vm._v(" Payment Methods ")])],1)],1),(this.getCustomerAdminIsAuthorizeToAccessToken)?_c('v-list-item',{class:_vm.$router.currentRoute.name === 'Settings' &&
									_vm.$store.state.page.currentSettingsTab == 2
										? 'active-link'
										: '',on:{"click":function($event){return _vm.onClickSettings(2)}}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":"/settings?tab=api-token"}},[_c('div',{staticClass:"icon-separator"}),_vm._v(" API Key & Token ")])],1)],1):_vm._e()],1)],1)],1)],1)],1),(_vm.isMobile)?_c('div',{staticClass:"mobile-line-separator"}):_vm._e(),(_vm.isMobile && 1 == 2)?_c('li',[_c('router-link',{class:{
					'active-link': _vm.$store.state.page.currentPage == 'profile',
				},attrs:{"to":"/profile"}},[_c('v-avatar',{staticStyle:{"margin-right":"10px"},attrs:{"size":"35"}},[_c('v-img',{attrs:{"src":"https://cdn.vuetifyjs.com/images/john.png"}}),_c('v-icon',{staticClass:"mr-2 account",attrs:{"small":""}},[_vm._v(" mdi-account ")])],1),_vm._v(" "+_vm._s(_vm.getUser ? JSON.parse(_vm.getUser).name : "")+" ")],1)],1):_vm._e(),(_vm.isMobile)?_c('li',{staticStyle:{"line-height":"28px","padding-top":"16px"}},[_c('div',{staticClass:"customer-lists-header"},[_vm._v("Accounts")])]):_vm._e(),(_vm.isMobile)?_c('div',_vm._l((_vm.customers),function(customer,key){return _c('li',{key:("customer-" + key),staticStyle:{"padding":"2px 0 2px 8px","color":"#e1ecf0 !important","cursor":"pointer"},on:{"click":function($event){return _vm.selectCustomer(customer)}}},[_c('div',{class:("default-customer-wrapper " + (customer.is_selected == 1
								? 'customer-selected' : ''))},[(1 == 2)?_c('v-avatar',{staticStyle:{"margin-right":"10px"},attrs:{"size":"35"}},[_c('v-icon',{staticClass:"mr-2 account",attrs:{"small":""}},[_vm._v(" mdi-account ")])],1):_vm._e(),_c('div',{staticClass:"customer-avatar-group-wrapper"},[_vm._m(0,true),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"90%"}},[_c('div',{staticClass:"customer-entity-name"},[_vm._v(" "+_vm._s(typeof customer !== "undefined" && typeof customer.name !== "undefined" ? customer.name : "")+" ")]),_c('div',{staticClass:"customer-entity-company-name"},[_vm._v(" "+_vm._s(typeof customer !== "undefined" && typeof customer.company_name !== "undefined" ? customer.company_name : "")+" ")])])]),(customer.is_selected == 1)?_c('div',{staticClass:"customer-arrow-wrapper"},[_c('svg',{attrs:{"width":"16","height":"14","viewBox":"0 0 16 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.68817 10.526L2.27614 6.9434C1.75544 6.39666 0.911221 6.39666 0.390523 6.9434C-0.130174 7.49013 -0.130174 8.37656 0.390523 8.92329L4.83496 13.5899C5.38936 14.1721 6.30013 14.1287 6.80206 13.4962L15.6909 2.29629C16.1623 1.7023 16.0859 0.819512 15.5202 0.324524C14.9545 -0.170464 14.1138 -0.0902105 13.6423 0.503775L5.68817 10.526Z","fill":"white"}})])]):_vm._e()],1),_vm._v(" "+_vm._s(/*getUser ? JSON.parse(getUser).name : ""*/)+" ")])}),0):_vm._e(),(_vm.isMobile)?_c('li',[_c('v-btn',{staticClass:"btn-logout-mobile",on:{"click":_vm.userLogout}},[_c('span',[_vm._v("Log out")]),_c('img',{staticStyle:{"margin-right":"10px"},attrs:{"src":require("@/assets/images/sign-out.png"),"alt":"","width":"20px"}})])],1):_vm._e()]):_vm._e(),_c('p',{staticClass:"footer"},[_vm._v("© "+_vm._s(_vm.currentYear)+" Shifl. All rights reserved.")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-avatar-wrapper"},[_c('img',{attrs:{"src":require("@/assets/icons/import-name-logo.svg")}})])}]

export { render, staticRenderFns }